import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import TextField from '@material-ui/core/TextField';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import { postContactForm } from './postData';
import { captcha_key } from '../../config/constants';
import { StyledForm } from './style';
import Button from '../Buttons';

const nameRegex = `^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$`;

const basicValidation = {
  firstName: Yup.string()
    .required('Required')
    .max(20, 'Too Long!')
    .matches(nameRegex, 'Only alphabets are allowed'),
  lastName: Yup.string()
    .max(20, 'Too Long!')
    .required('Required')
    .matches(nameRegex, 'Only alphabets are allowed'),
  email: Yup.string().email('Invalid email').required('Required'),
  message: Yup.string().required('Required'),
  captcha: Yup.string().required('Please verify CAPTCHA'),
};

const linkedinValidation = {
  linkedinURL: Yup.string()
    .required('Required')
    .matches(
      '^(http(s)?://)?([w]+.)?linkedin.com/(pub|in|profile)',
      'Please enter a valid link',
    ),
};

const ContactForm = ({ formType, btnText, askCompanyName, askLinkedinURL }) => {
  let formSchema = Yup.object().shape(basicValidation);
  askLinkedinURL &&
    (formSchema = Yup.object().shape({
      ...basicValidation,
      ...linkedinValidation,
    }));

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        linkedinURL: '',
        message: '',
        captcha: '',
      }}
      validationSchema={formSchema}
      onSubmit={(values, { resetForm }) => {
        resetForm({ values: '' });
        postContactForm(values, formType);
      }}
    >
      {({ values, touched, errors, setFieldValue, handleChange }) => (
        <StyledForm>
          <Form>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <TextField
                    name="firstName"
                    label="First Name *"
                    value={values.firstName}
                    onChange={handleChange}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <TextField
                    name="lastName"
                    label="Last Name *"
                    value={values.lastName}
                    onChange={handleChange}
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <TextField
                    name="email"
                    label="Email *"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </div>
              </div>
              {askCompanyName && (
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      name="companyName"
                      label="Company Name"
                      value={values.companyName}
                      onChange={handleChange}
                      error={touched.companyName && Boolean(errors.companyName)}
                      helperText={touched.companyName && errors.companyName}
                    />
                  </div>
                </div>
              )}
              {askLinkedinURL && (
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      name="linkedinURL"
                      label="Linkedin URL *"
                      value={values.linkedinURL}
                      onChange={handleChange}
                      error={touched.linkedinURL && Boolean(errors.linkedinURL)}
                      helperText={touched.linkedinURL && errors.linkedinURL}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="form-group">
              <TextField
                name="message"
                label="Your Message *"
                value={values.message}
                onChange={handleChange}
                multiline
                maxRows={4}
                error={touched.message && Boolean(errors.message)}
                helperText={touched.message && errors.message}
              />
            </div>

            <HCaptcha
              sitekey={captcha_key}
              onVerify={(token, ekey) => setFieldValue('captcha', ekey)}
            />

            <Button
              btnText={btnText || 'Submit'}
              btnType="rounded-outline"
              minW="13rem"
              className="mt-5"
              type="submit"
            />
          </Form>
        </StyledForm>
      )}
    </Formik>
  );
};

ContactForm.propTypes = {
  formType: PropTypes.string,
  btnText: PropTypes.string,
  askCompanyName: PropTypes.bool,
  askLinkedinURL: PropTypes.bool,
};

ContactForm.defaultProps = {
  formType: 'inquiry',
};

export default ContactForm;
