import axios from 'axios';
import toast from 'react-hot-toast';

const postURL = 'https://contact-api.optimumfuturist.com.np/api/contact-form';

export const postContactForm = (values, type) => {
  let data = {
    form_type: type,
    f_name: values.firstName,
    l_name: values.lastName,
    email: values.email,
    msg: values.message,
  };

  values.companyName && (data.company = values.companyName);
  values.linkedinURL && (data.linkedin = values.linkedinURL);

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  axios
    .post(postURL, data, {
      headers: headers,
    })
    .then(function (response) {
      toast.success(response.data.data.message);
    })
    .catch(function (error) {
      console.log(error);
      error?.response?.status === 422 &&
        toast.error(error.response.data.message);
    });
};
