import styled from 'styled-components';
import colors from '../../layout/globalColors';
import { device } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';

export const ContactusWrapper = styled(Wrapper)`
  padding: 8rem 0;

  .contact-tabs {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;

    .nav-tabs {
      border-bottom: none;
    }

    nav {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 3rem;
    }

    .nav-link:not(.active) {
      color: ${colors.cadetBlue};
    }

    @media ${device.tablet} {
      flex-direction: row;
      margin-top: 4rem;

      nav {
        width: 32%;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 0rem;
      }

      .tab-content {
        width: 68%;
      }
    }
  }

  .nav-link {
    position: relative;
    padding: 1.5rem 0;
    font-size: 0.8rem;
    font-weight: 500;

    &.active::before {
      content: '';
      width: 0.6rem;
      height: 0.6rem;
      background: ${colors.secondary};
      display: flex;
      border-radius: 0.6rem;
      position: absolute;
      left: 50%;
      bottom: 0.8rem;
      transform: translateX(-50%);
      transition: all 0.3s ease-in;
    }

    @media ${device.tablet} {
      font-size: 1.2rem;

      &::before {
        content: '';
        width: 0.6rem;
        height: 0.6rem;
        background: ${colors.secondary};
        display: flex;
        border-radius: 0.6rem;
        position: absolute;
        left: -1rem;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease-in;
      }

      &.active::before {
        bottom: auto;
        left: -1rem;
        top: 50%;
        transform: translateY(-50%);
        height: 3rem;
      }
    }

    @media ${device.laptop} {
      font-size: 1.4rem;
      &::before {
        left: -3rem;
      }

      &.active::before {
        left: -3rem;
      }
    }
  }

  button {
    margin-top: 1rem;
  }
`;

export const StyledForm = styled.div`
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.5rem;
    position: relative;

    label {
      font-size: 1.1rem;
      text-transform: uppercase;
      pointer-events: none;

      span {
        color: ${colors.secondary};
      }
    }
  }

  input,
  textarea {
    background: transparent;
    font-size: 1.3rem;
    padding: 0.5rem 0;

    /* Change the white to any color */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${colors.primary} inset !important;
      -webkit-text-fill-color: ${colors.white}!important;
    }
  }

  .custom-file-input {
    position: relative;
    input[type='file'] {
      opacity: 0;
      width: 100%;
    }

    input[type='text'] {
      border: none;
      position: absolute;
      pointer-events: none;
      left: 0;
      text-overflow: ellipsis;
      width: calc(100% - 5rem);
    }

    & ~ label {
      bottom: -2rem;
      transition: all 0.2s ease-in;
    }

    &.selected ~ label {
      top: -1.2rem;
      font-size: 0.7rem;
      transition: all 0.2s ease-in;
    }

    ::after {
      content: '';
      width: 100%;
      display: block;
      position: relative;
      margin-top: -8px;
      border-bottom: solid 2px ${colors.cadetBlue};
    }

    & .custom-btn-label {
      position: absolute;
      top: 1rem;
      right: 0;
      opacity: 0;
      pointer-events: none;
    }
    & label.custom-btn-label.show {
      opacity: 1;
    }
  }

  .MuiInputBase-input.MuiInput-input {
    color: #fff;
  }

  .MuiFormLabel-root.MuiInputLabel-root {
    color: ${colors.white};

    &.Mui-focused {
      color: ${colors.secondary};
    }

    &.Mui-error {
      color: ${colors.error};
    }
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid ${colors.white};
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid ${colors.white};
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid ${colors.secondary};
  }
`;
